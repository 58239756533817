.section-footer {
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        h2 {
            margin-bottom: 2rem;
        }
    }
}
