.header.hide {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
}

.header.show {
    transition: transform 0.3s ease-in-out;
}

.header {
    background-color: $color-grey-light-1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    height: 9rem;
    &__nav-mob {
        display: none;
    }
    @include respond(tab-land) {
        height: 9rem;
    }

    &__nav-mob {
        @include respond(width-for-header-breakpoint) {
            display: block;
            .fa-bars {
                font-size: 4rem;
                color: $color-grey-dark;
            }
        }
        @include respond(phone) {
            padding-right: 4rem;
        }
    }
    &__nav-desktop {
        @include respond(width-for-header-breakpoint) {
            display: none;
        }
        margin: 0 auto;
        width: $grid-width;
        margin-left: calc((100% - #{$grid-width}) / 8);
        .nav-links {
            display: flex;
            justify-content: space-between;
            .nav-link {
                text-decoration: none;
                text-transform: uppercase;
                color: $color-grey-dark;
                font-size: 1.6rem;
                padding: 0.5rem 1.5rem;
                border-radius: 0.2rem;
                transition: all 0.3s;
                &:hover {
                    background-color: $color-grey-dark;
                    color: $color-white;
                }
            }
        }
    }
    .header__placeholder {
        width: calc(calc(((100% - #{$grid-width}) / 2) / 4) + 8rem);
        flex-shrink: 0; // to prevent shrinking
    }
}

.header {
    .row-flex {
        width: $grid-width;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
