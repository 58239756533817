//COLORS
$color-grey-dark: rgb(83, 82, 82);
// $color-grey-light-1: rgba(201, 199, 195, 0.1);
// $color-grey-light-2: rgba(201, 199, 195, 0.4);
$color-grey-light-1: #eee;
$color-grey-light-2: rgb(220, 217, 217);

$color-logo-green: #28353b;
$color-coral: #fe6152;

$color-white: #fff;
$color-black: #000;

// FONT

$default-font-size: 1.6rem;
$default-font-family: 'Dosis', sans-serif;

//GRID
$grid-width: 114rem;
$grid-small: 41rem;
$grid-big: 140rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 4rem;
$gutter-horizontal: 6rem;
