.row {
    max-width: $grid-width;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    &.small {
        max-width: $grid-small;
    }
    &.big {
        max-width: $grid-big;
        padding-left: 4rem;
        padding-right: 4rem;
        .heading-primary.mob {
            @include respond(tab-port) {
                margin-bottom: 4rem;
            }
        }
        .col-4-of-12 .mob-flex {
            margin-bottom: 0;
        }
        .mob-flex {
            .paragraph {
                width: 46rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .paragraph-inner-box {
                    p {
                        hyphens: auto;
                        word-wrap: break-word;
                    }
                }
                @include respond(desktop) {
                    width: 38rem;
                }
                @include respond(tab-land) {
                    width: 27.5rem;
                }
                @include respond(tab-port) {
                    width: 32rem;
                }
                @include respond(phone) {
                    width: 32rem;
                }
            }
            .heading-tertiary {
                @include respond(desktop) {
                    height: 6rem;
                }
                @include respond(tab-land) {
                    height: 4.5rem;
                }
                @include respond(tab-port) {
                    height: 3rem;
                    text-align: center;
                }
                @include respond(phone) {
                    height: 2rem;
                    font-size: 1.6rem;
                }
            }
            // .mid {
            //     @include respond(phone) {
            //         text-align: left;
            //     }
            // }
            @include respond(tab-port) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
    &:not(:last-child) {
        margin-bottom: $gutter-vertical;
        @include respond(tab-port) {
            margin-bottom: $gutter-vertical-small;
        }
    }
    @include respond(tab-land) {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    @include respond(tab-port) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    @include clearfix;
    [class^='col-'] {
        //elements with atributes class which has start col-
        float: left;

        &:not(:last-child) {
            margin-right: $gutter-horizontal;
            @include respond(tab-port) {
                margin-right: 0;
                margin-bottom: $gutter-vertical-small;
            }
        }

        @include respond(tab-port) {
            width: 100% !important;
        }
    }
    @for $i from 1 through 12 {
        .col-#{$i}-of-12 {
            width: calc(
                #{$i} *
                    ((100% - 11 * #{$gutter-horizontal}) / 12) +
                    (#{$i} - 1) *
                    #{$gutter-horizontal}
            );
        }
    }
}
