.form-box {
    width: 100%;
    form {
        display: grid;
        grid-template-columns: 1fr;
        .cell-1 {
            margin-bottom: 2rem;
            input,
            textarea {
                width: 100%;
                padding: 0.5rem 1rem;
                background-color: $color-grey-light-2;
                border: 0.1rem solid $color-grey-dark;
                border-radius: 0.2rem;
            }
            input,
            textarea:focus {
                outline: none;
            }
            .file-input {
                font-size: 1.4rem;
                // display: inline-block;
                // padding: 10px;
                // background-color: #eee;
                // border: 1px solid #ccc;
                // cursor: pointer;
            }
            // input[type='file'] {
            //     display: none;
            // }
        }
        .submit-btn {
            width: 10rem;
            padding: 0.5rem 1rem;
            background-color: $color-grey-dark;
            color: white;
            border: 1px solid $color-grey-dark;
            border-radius: 0.2rem;
            cursor: pointer;
            transition: all 0.3s;
        }
        .submit-btn:hover {
            background-color: $color-grey-light-2;
            color: $color-grey-dark;
        }
    }
}
