@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both; //clear both floats - float-left and float-right
    }
}

@mixin image-fit {
    // width: 100%;
    object-fit: cover;
    object-position: center;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin thumb-style {
    width: 1rem;
    height: 1rem;
    background-color: $color-grey-dark;
    cursor: pointer;
    border-radius: 50%;
}

//MEDIA QUERY MANAGER
/*
0 - 600px:    Phone 
600 - 900px:    Tablet portrait
900 - 1200px:    Tablet landscape
[1200 - 1800px] is where normal style apply
1800px + :    Big desktop

$breakpoint argument choise:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone-sm {
        @media (max-width: 28.5em) {
            //448px
            @content;
        }
    }
    @if $breakpoint == phone {
        @media (max-width: 37.5em) {
            //600px
            @content;
        }
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) {
            //900px
            @content;
        }
    }
    @if $breakpoint == width-for-header-breakpoint {
        @media (max-width: 67.5em) {
            //1080px
            @content;
        }
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) {
            //1200px
            @content;
        }
    }
    @if $breakpoint == desktop {
        @media (max-width: 88.1875em) {
            //1411px
            @content;
        }
    }
    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) {
            //1800px
            @content;
        }
    }
}
