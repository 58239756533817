*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    //this font size defines, what 1 rem is.
    font-size: 62.5%;

    @include respond(tab-land) {
        // width < 1200 px
        font-size: 56, 25%; //1rem - 9px, 9/16 = 56,25%
    }

    @include respond(tab-port) {
        // width < 900 px
        font-size: 50%; //1rem - 8px, 8/16 = 50%
    }

    @include respond(big-desktop) {
        font-size: 80%;
    }
}

body {
    font-family: $default-font-family;
    font-weight: 400;
    line-height: 1.7;
    background-color: $color-grey-light-1;
    color: $color-grey-dark;
    box-sizing: border-box;

    @include respond(tab-port) {
        padding: 0;
    }
}
