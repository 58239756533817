.u-center-text {
    text-align: center;
}

.u-margin-bottom-small {
    margin-bottom: 1.5rem;
}

.u-margin-bottom-medium {
    margin-bottom: 2rem;
}

.u-margin-bottom-big {
    margin-bottom: 8rem;
    @include respond(tab-land) {
        margin-bottom: 6rem;
    }
    @include respond(tab-port) {
        margin-bottom: 4rem;
    }
    @include respond(phone) {
        margin-bottom: 2rem;
    }
}

.u-margin-bottom-big2 {
    margin-bottom: 7rem;
}

.u-section-padding {
    padding: 10rem 0;
    @include respond(tab-land) {
        padding: 6rem 0;
    }
    @include respond(tab-port) {
        padding: 4rem 0;
    }
}

.u-section-padding-bottom {
    padding-bottom: 10rem;
    @include respond(phone) {
        padding-bottom: 6rem;
    }
}

.u-padding-bottom {
    line-height: 5rem;
}

.bottom-border {
    padding-bottom: 10rem;
    border-bottom: 1px solid $color-logo-green;
}

.u-justify {
    text-align: justify;
}
