.heading-primary {
    color: $color-grey-dark;
    text-transform: uppercase;
    font-size: 2.4rem;

    @include respond(tab-land) {
        font-size: 2.2rem;
    }

    @include respond(tab-port) {
        font-size: 2rem;
    }
}

.heading-secondary {
    font-size: 2.2rem;
    text-transform: uppercase;
    @include respond(tab-land) {
        font-size: 2.1rem;
    }
    @include respond(tab-port) {
        font-size: 2rem;
    }
    @include respond(phone) {
        font-size: 1.8rem;
    }
}

.heading-tertiary {
    font-size: 2rem;
    text-transform: uppercase;
    @include respond(tab-land) {
        font-size: 1.7rem;
    }
    @include respond(tab-port) {
        font-size: 1.6rem;
    }
    @include respond(phone) {
        font-size: 1.4rem;
    }
}

.paragraph {
    font-size: $default-font-size;
}
