.section-services {
    .services {
        font-size: $default-font-size;
        font-weight: 500;
    }
    .paragraph {
        margin-top: 50px;
        font-weight: 600;
    }
}
